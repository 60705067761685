import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import loginImg from '../sass/img/images/login.png'
import homeImg from '../sass/img/images/home.png'
import createNew from '../sass/img/images/createNew.png'
import portsImg from '../sass/img/images/ports.png'
import addedStream from '../sass/img/images/addedStream.png'
import stream from '../sass/img/images/stream.png'
import DocInfoCode from "../components/SyntaxHighlighter";
import { L10nProvider } from '@logdoc/l10n';

const Fir = () => {
    return (
        <L10nProvider>
            <FirstRun />
        </L10nProvider>
    )
}

export const FirstRun = () => {
    const t = useLocale()
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>{t('virgin.breadcrumbs.1')}</span>
                    <span>/</span>
                    <span>{t('virgin.breadcrumbs.2')}</span>
                </div>
                <h3 className={classes.secSubtitle}>
                    {t('virgin.title')}
                </h3>
                <div className={classes.secBlock}>
                    <p>{t('virgin.description.p1')}</p>
                    <img className={classes.image} src={loginImg}/>
                    <div className={classes.secDescription}>
                        <p>
                            {t('virgin.description.p2')} <span className={classes.textOrange}>admin</span> / <span
                            className={classes.textOrange}>nimda</span> - {t('virgin.description.p3')}
                        </p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <img className={classes.image} src={homeImg}/>
                    <div className={classes.secDescription}>
                        <p>{t('virgin.description.p4')}<br/>
                        </p>
                        <p>
                            {t('virgin.description.p5')}<br/>
                            {t('virgin.description.p6')}
                        </p>
                        <p>{t('virgin.description.p7')}</p>
                        <img className={classes.image} src={portsImg}/>
                        <p>
                            {t('virgin.description.p8')} <strong>Logdoc-Syslog-Udp-Handler-UDP</strong> {t('virgin.description.p9')} <strong>Logdoc-Syslog-Tcp-Handler-TCP</strong>.<br/>
                            {t('virgin.description.p10')}
                        </p>
                        <p>{t('virgin.description.p11')}</p>
                        <img className={classes.image} src={homeImg}/>
                        <p>
                            {t('virgin.description.p12')} <strong>+</strong> {t('virgin.description.p13')}
                        </p>
                        <img className={classes.image} src={createNew}/>
                        <p>{t('virgin.description.p14')}</p>
                        <img className={classes.image} src={addedStream}/>
                        <p>{t('virgin.description.p15')}</p>
                        <p>{t('virgin.description.p16')}</p>
                    </div>
                    <div className={classes.secDescription}>
                        <p>
                            <ul>
                                <li>{t('virgin.description.p17')}
                                    <DocInfoCode text={`*.*     @localhost:5566`} language={`conf`}/>
                                </li>
                                <li>{t('virgin.description.p18')}
                                    <DocInfoCode text={`*.*     @@localhost:5566`} language={`conf`}/>
                                </li>
                                <li>{t('virgin.description.p19')}
                                    <DocInfoCode text={`sudo service rsyslog restart`} language={`bash`}/>
                                </li>
                            </ul>
                        </p>
                        <p>{t('virgin.description.p20')}</p>
                        <img className={classes.image} src={stream}/>
                    </div>
                    <div className={classes.secDescription}>
                        <p>{t('virgin.description.p21')}</p>
                    </div>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default Fir;
