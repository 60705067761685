import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { FirstRun } from '../virgin'

const Fir = () => {
    return (
        <L10nProvider>
            <FirstRun />
        </L10nProvider>
    )
}

export default Fir;
